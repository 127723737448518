/* eslint-disable */
import { lazy } from 'react';
import { DEFAULT_PATHS } from 'config.js';


const metric = {
  index: lazy(() => import('views/metric/index')),
  grid: lazy(() => import('views/metric/MetricPageGrid')),
};

const settings = {
  role: lazy(() => import('views/settings/role/RolesView')),
  terms: lazy(() => import('views/settings/terms/Terms')),
  user: lazy(() => import('views/settings/user/UserView')),
  event: lazy(() => import('views/settings/event/Event')),
  charts: lazy(() => import('views/settings/chart/ChartView')),
  global_filters: lazy(() => import('views/settings/global_filter/GlobalFilters')),
  tableau: lazy(() => import('views/settings/tableau/TableauSettingsView')),
  tableau_externals: lazy(() => import('views/settings/tableau/TableauExternalView')),
  site_config: lazy(() => import('views/settings/site_config/SiteConfigView')),
  languages: lazy(() => import('views/settings/languages/LanguageView')),
};

const pages = {
  index: lazy(() => import('views/pages/Pages')),
  authentication: {
    login: lazy(() => import('views/default/Login')),
  },

};

const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;

const routesAndMenuItems = {
  mainMenuItems: [
    {
      path: `${appRoot}/`,
      component: metric.index,
      label: 'menu.dashboards',
      protected: true,
      icon: 'home',
      subs: [
        { path: '/', label: 'menu.index', component: metric.index },
      ],
    },
    {
      path: `${appRoot}/metric`,
      component: metric.index,
      label: 'menu.dashboards',
      protected: true,
      icon: 'home',
      subs: [
        { path: '/:id', label: 'menu.index', component: metric.index },
        { path: '/', label: 'menu.index', component: metric.index },
      ],
    },
    {
      path: `${appRoot}/metrics`,
      component: metric.grid,
      exact: true,
      label: 'menu.metrics',
      protected: true,
      icon: 'home',
      subs: [
        { path: '/grid', label: 'menu.grid', component: metric.grid },
      ],
    },
    {
      path: `${appRoot}/role`,
      component: settings.role,
      exact: true,
      label: 'menu.role',
      protected: true,
      icon: 'home',
    },

    {
      path: `${appRoot}/user`,
      component: settings.user,
      exact: true,
      label: 'menu.user',
      protected: true,
      icon: 'home',
    },

    {
      path: `${appRoot}/event`,
      component: settings.event,
      exact: true,
      label: 'menu.event',
      protected: true,
      icon: 'home',
    },

    {
      path: `${appRoot}/charts`,
      component: settings.charts,
      exact: true,
      label: 'menu.charts',
      protected: true,
      icon: 'home',
    },

    {
      path: `${appRoot}/global_filters`,
      component: settings.global_filters,
      exact: true,
      label: 'menu.global_filters',
      protected: true,
      icon: 'home',
    },

    {
      path: `${appRoot}/tableau/settings`,
      component: settings.tableau,
      exact: true,
      label: 'menu.tableau',
      protected: true,
      icon: 'home',
    },
    {
      path: `${appRoot}/tableau/externals`,
      component: settings.tableau_externals,
      exact: true,
      label: 'menu.tableau_externals',
      protected: true,
      icon: 'home',
    },
    {
      path: `${appRoot}/site/config`,
      component: settings.site_config,
      exact: true,
      label: 'menu.site_config',
      protected: true,
      icon: 'home',
    },
    {
      path: `${appRoot}/languages`,
      component: settings.languages,
      exact: true,
      label: 'menu.languages',
      protected: true,
      icon: 'home',
    },
    {
      path: DEFAULT_PATHS.APP,
      exact: true,
      redirect: true,
      to: `${appRoot}/metrics`,
    },

    {
      path: `${appRoot}/terms`,
      label: 'menu.terms',
      icon: 'notebook-1',
      component: settings.terms,
      noLayout: true,
    },
    {
      path: `${appRoot}/pages`,
      label: 'menu.pages',
      icon: 'notebook-1',
      component: pages.index,
      subs: [
        {
          path: '/authentication',
          label: 'menu.authentication',
          component: pages.authentication.index,
          subs: [
            { path: '/login', label: 'menu.login', component: pages.authentication.login, noLayout: true },
          ],
        },
      ],
    },
  ],
};
export default routesAndMenuItems;
