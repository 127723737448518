import React, { memo, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loadUser } from 'redux/auth/authSlice';

const RouteItem = ({ redirect = false, exact = false, path, to, component }) => {
  const { loggedIn, loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUser());
  }, []);

  if (loading) {
    return (
      <div className="container-fluid d-flex align-items-center justify-content-center">
        <Spinner animation="border" style={{ width: '3rem', height: '3rem' }} />
      </div>
    );
  }

  if (redirect && !loggedIn) {
    return (
      <Redirect to="/login"></Redirect>
    );
  }
  const props = {};
  
  if (path) props.path = path;
  if (exact) props.exact = exact;
  if (component) props.component = component;

  return <Route {...props} />;
};

export default memo(RouteItem);
