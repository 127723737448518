import axios from 'axios';
import { BACKEND_URL } from 'config.js';


class LanguageService {

  constructor(props) {
  }

  getTranslation = (token) => {
    var value = localStorage.getItem(token);
    return undefined === value ? '{{' + token + '}}' : value;
  }

  // Get translations
  load = async () => {
    var data = await axios.get(BACKEND_URL + 'translation/1')
      //.then(function (data) {
        var translations = (data.status == 200 ? data.data : data);
        if (translations) {
          var keys = Object.keys(translations);
          for (var index = 0; index < keys.length; index++){
            localStorage.setItem(keys[index], translations[keys[index]]);
          }
        }
      //});
  }

  getLanguages = () => {
    var apiUrl = BACKEND_URL + "translation/langlist/lngs";
    return axios.get(apiUrl);
  }

  saveTranslation = (data) => {
    var apiUrl = BACKEND_URL + "translation/saveTranslation";
    return axios.post(apiUrl, data);
  }

  deleteTranslation = (data) => {
    var apiUrl = BACKEND_URL + "translation/delete/" + data.id;
    return axios.delete(apiUrl);
  }
}

export default (new LanguageService());