import { createSlice } from '@reduxjs/toolkit';


export const initialState = {
  uploadResult: {},
};

const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    fileuploadResult: (state, { payload }) => {
      state.uploadResult = payload;
    },
  },
});

// The reducer
export default uploadSlice.reducer;

// Actions
export const {
  fileuploadResult,
} = uploadSlice.actions;

export const applyFileuploadResult = (data) => async (dispatch) => {
  try {
    dispatch(fileuploadResult(data));
  } catch (err) {
    return console.log(err.message);
  }
};
