import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { BACKEND_URL } from 'config.js';


export const initialState = {
  currentUser: {
    email: null,
    name: null,
    firstname: null,
    lastname: null,
    role: null,
    designation: null,
    profile: null,
    profile_image: null
  },
  loggedIn: false,
  loading: true,
  error: null,
  token: null
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      const token = localStorage.getItem('trentoken');
      const isAdmin = localStorage.getItem('isAdmin');
      if (token) {
        var data = JSON.parse(window.atob(token.split('.')[1]));
        if (data.exp > Date.now() / 1000) {
          var user = {
            email: data.email,
            name: data.name,
            firstname: data.firstname,
            lastname: data.lastname,
            role: (isAdmin === 'true'),
            designation: data.designation,
            profile: data.profile,
            profile_image: data.profile_image
          };
          state.loggedIn = true;
          state.error = null;
          state.loading = false;
          state.currentUser = user;
        } else {
          state.loggedIn = false;
          state.error = null;
          state.loading = false;
          state.currentUser = {};
        }
      } else {
        state.loggedIn = false;
        state.error = null;
        state.loading = false;
        state.currentUser = {};
      }
    },
    logoutSuccess: (state, action) => {
      localStorage.removeItem('trentoken');
      localStorage.removeItem('isAdmin');
      state.currentUser = {};
      state.loggedIn = false;
      state.loading = false;
      state.error = null;
      state.token = null;
    },
    setUserData: (state, { payload }) => {
      state.token = payload.token;

      if (state.token) {
        var data = JSON.parse(window.atob(state.token.split('.')[1]));
        var user = {
          email: data.email,
          name: data.name,
          firstname: data.firstname,
          lastname: data.lastname,
          role: payload.is_admin,
          designation: data.designation,
          profile: data.profile,
          profile_image: data.profile_image
        };
        
        state.loading = false;
        state.error = null;
        state.currentUser = user;
      }
    },
    setAuthError: (state, action) => {
      state.currentUser = {
        email: null,
        name: null,
        firstname: null,
        lastname: null,
        role: null,
        designation: null,
        profile: null,
        profile_image: null
      };
      state.loggedIn = false;
      state.loading = false;
      state.token = null;
      state.error = action.payload;
    },
  },
});

// The reducer
export default authSlice.reducer;

// Actions
export const {
  loginSuccess,
  logoutSuccess,
  setUserData,
  setLoading,
  setAuthError,
} = authSlice.actions;

export const loadUser = () => async (dispatch) => {
  try {
    dispatch(setLoading());
    dispatch(loginSuccess());
  } catch (err) {
    dispatch(setAuthError(err.response?.data.msg));
    return console.log(err.message);
  }
};

export const login = (formdata) => async (dispatch) => {
  try {
    dispatch(setLoading());
    var apiUrl = BACKEND_URL + "login";
    const res = await axios.post(apiUrl, formdata);

    if (res.status == 200) {
      localStorage.setItem('trentoken', res.data.token);
      localStorage.setItem('isAdmin', res.data.is_admin);
    }

    //dispatch(setUserData(res.data));
    dispatch(loginSuccess());
  } catch (err) {
    //dispatch(setAuthError(err.response.data.msg));
    return console.log(err.message);
  }
};

export const logout = () => async (dispatch) => {
  try {
    await axios.get(BACKEND_URL + "/logout");
    return dispatch(logoutSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
