import { createSlice } from '@reduxjs/toolkit';


export const initialState = {
  filtertype: '',
  filtervalue: '',
  filterMethodtype: '',
  filterCount: 0,
  show: false,
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    doApplyGlobalFilter: (state, { payload }) => {
      state.filter_fields = payload.filter_fields;
      state.filtertype = payload.filtertype;
      state.filtervalue = payload.filtervalue;
      state.filterMethodtype = payload.filterMethodtype;
      state.filterCount = 0;
      
      for (const key in state.filter_fields) {
        let empty = false;
        for (var index = 0; index < state.filter_fields[key].length; index++) {
          if ((state.filter_fields[key][index]['value'] !== '(All)')) {
            state.filterCount += 1;
          }
        }
      }
    },
    doApplyGlobalFilterShow: (state, { payload }) => {
      state.show = payload.show;
    },
  },
});

// The reducer
export default filterSlice.reducer;

// Actions
export const {
  doApplyGlobalFilter,
  doApplyGlobalFilterShow,
} = filterSlice.actions;

export const applyGlobalFilter = (values) => async (dispatch) => {
  try {
    dispatch(doApplyGlobalFilter(values));
  } catch (err) {
    return console.log(err.message);
  }
};

export const applyGlobalFilterShow = (values) => async (dispatch) => {
  try {
    dispatch(doApplyGlobalFilterShow(values));
  } catch (err) {
    return console.log(err.message);
  }
};