export const DIMENSION = {
  Desktop: 'desktop',
  Tablet: 'tablet',
  Mobile: 'mobile',
};

export const LAYOUT = {
  Fluid: 'fluid',
  Boxed: 'boxed',
};

export const RADIUS = {
  Rounded: 'rounded',
  Standard: 'standard',
  Flat: 'flat',
};

export const THEME_COLOR = {
  LightBlue: 'light-blue',
  DarkBlue: 'dark-blue',
  LightRed: 'light-red',
  DarkRed: 'dark-red',
  LightGreen: 'light-green',
  DarkGreen: 'dark-green',
  LightPurple: 'light-purple',
  DarkPurple: 'dark-purple',
  LightPink: 'light-pink',
  DarkPink: 'dark-pink',
};

export const NAV_COLOR = {
  Default: 'default',
  Light: 'light',
  Dark: 'dark',
};
export const MENU_PLACEMENT = {
  Vertical: 'vertical',
  Horizontal: 'horizontal',
};
export const MENU_BEHAVIOUR = {
  Pinned: 'pinned',
  Unpinned: 'unpinned',
};

export const USER_ROLE = {
  Admin: 1,
  Editor: 0,
};






//Converted constants  
export const TILE_MIN_WIDTH = 3;
export const TILE_MIN_HEIGHT = 3;
export const TILE_MIN_WIDTH_TRENDLINE = 12;
export const TILE_MIN_HEIGHT_TRENDLINE = 6;
export const TILE_MIN_HEIGHT_WITH_FILTERS = 7;

// translation
export const LOCALES = {
  'locales': {
    'ru_RU': 'Русский',
    'en_US': 'English'
  },
  'preferredLocale': 'en_US'
}

// base api url

export const BASE_URL = window.location.origin + '/api/';

// tooltip
export const TOOLTIP_MESSAGES = {
  LAYOUT: {
    TOGGLE_MENU: 'Toggle Menu',
    CHART_BUILDER: 'Create Custom Chart',
    ADD_TILES: 'Add Charts',
    EVENT_MANAGEMENT: 'Manage Events'
  },
  USER_MANAGEMENT: {
    ADD_NEW_USER: 'Add New User',
    SUSPEND_USER: 'Suspend User',
    CHANGE_USER: 'Change User Role',
    DELETE_USER: 'Delete User'
  },
  GRIDSTACK: {
    RESIZE_HANDLE: 'Resize chart'
  },
  TILES: {
    DOWNLOAD_CHART: 'Download Chart',
    CHART_OPTIONS: 'Chart Option',
    ADD_TO_DASHBOARD: 'Add to Dashboard',
    ADD_TO_DASHBOARD_NOTY: 'Chart successfully added to Dashboard'
  },
  BACK_BUTTON: 'Back button',
  PROFILE_UPDATE: 'Profile updated successfully.',
  ERROR_NOTY: 'There is some error'
}

export const ALLOWED_CHART_TYPES = [
  { type: 'scrollline2d', icon: 'lnr ic-line-chart', tooltip: 'Line Chart' },
  { type: 'scrollcolumn2d', icon: 'lnr ic-bar-chart', tooltip: 'Column Chart' },
  { type: 'scrollarea2d', icon: 'lnr ic-area-chart', tooltip: 'Area Chart' },
  { type: 'mssplinearea', icon: 'lnr ic-spline-area-chart', tooltip: 'Spline Chart' }
]

//chart colors
export const COLORS = {
  'CHART_TRENDLINE': '#008ee4',
  'CHART_PREDICTIVE_DATE': '#FFA500',
  'CHART_PREDICTIVE': '#F3A09E',
  'CHART_PAST': '#0075C2',
  'PREDICTIVE_COLORS': ['#FFA500', '#ffcc99']
}

export const COST_PER_HIRE = 4219
export const PREDICTIVE_CHART_CORRECTION = 6;
export const ANALYTICS_CHART_CORRECTION = new Date().getMonth()

export const TRENDLINE_START_SETTINGS = {
  'renderAs': 'line',
  'showValues': '0',
  'id': 'trendline'
}

export const DEFAULT_ANNOTATION = {
  'origw': '450',
  'origh': '300',
  'autoscale': '1',
  'showBelow': '0'
}

export const MSCOLUMN2D_WITH_PERCENT = {
  'theme': 'tren',
  'numberPrefix': '',
  'numberScaleValue': '1',
  'numberScaleUnit': ' %',
  'chartBottomMargin': '40'
}

export const MSCOLUMN2D_WITH_CURRENCY = {
  'theme': 'tren',
  'numberPrefix': '',
  'numberScaleValue': '1',
  'numberScaleUnit': ' $',
  'chartBottomMargin': '40'
}

export const COLUMN2D_WITH_$ = {
  'theme': 'tren',
  'numberPrefix': '',
  'numberScaleValue': '1',
  'numberScaleUnit': ' $',
  'paletteColors': '#0075c2',
  'chartBottomMargin': '40'
}

export const COLUMN2D_PERCENT = {
  'theme': 'tren',
  'numberPrefix': '',
  'numberScaleValue': '1',
  'numberScaleUnit': ' %',
  'paletteColors': '#0075c2',
  'chartBottomMargin': '40'
}

export const ANNOTATIONS_HEX_RANGE = 'BCDEF';

export const ROW_NAMES = {
  avgSalary: 'Avg. Salary',
  remoteEmployees: 'Remote Employees',
  hpInProfDev: 'HP in Prof Dev',
  numberOfEmployees: 'Number of Employees',
  recruiting: 'Recruiting',
  totalRecruitingCost: 'Total Recruiting Cost',
  totalTurnover: 'Total Turnover',
  nonHpTurnover: 'Non-HP Turnover',
  hpTurnover: 'HP Turnover',
  turnoverProductivityLoss: 'Turnover Productivity Loss',
  turnoverProdLossOfRev: 'Turnover Prod Loss % of Rev',
  companyRev: 'Company Rev(Annualized)'
}

export const GRID_FILTER_MODEL = {
  location: {
    name: 'Location',
    modelKey: 'location',
    label: '',
    values: {}
  },
  gender: {
    name: 'Gender',
    modelKey: 'gender',
    label: '',
    values: {}
  },
  department: {
    name: 'Department',
    modelKey: 'department',
    label: '',
    values: {}
  }
}

export const CHART_VALUES_TYPES = ['solo', 'soloPercent', 'multi', 'multiPercent', 'soloValue', 'multiValue'];

export const CHART_THEMES = {
  'multiPercent': { 'theme': 'tren', 'numberPrefix': '', 'numberScaleValue': '1', 'numberScaleUnit': ' %', 'chartBottomMargin': '40' },
  'multi': { 'theme': 'tren', 'numberPrefix': '', 'chartBottomMargin': '40' },
  'soloPercent': { 'theme': 'tren', 'numberPrefix': '', 'paletteColors': '#0075c2', 'numberScaleValue': '1', 'numberScaleUnit': ' %', 'chartBottomMargin': '40' },
  'solo': { 'theme': 'tren', 'paletteColors': '#0075c2', 'numberPrefix': '', 'chartBottomMargin': '40' }
}

export const googleClientID = '888875361512-mq0fb1cobvi6a70odctbsrb7td6rvvk3.apps.googleusercontent.com';
export const clientLogo = 'client_logo';
export const EXCEPTIONS_CONSTANTS = { nulldata: 'server gone away! try again later.' }
