import { createSlice } from '@reduxjs/toolkit';


export const initialState = {
  current: null,
  items: [],
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    doApplyMenu: (state, { payload }) => {
      console.log(payload);
      state.items = payload.items;
    },
    doApplyCurrentMenu: (state, { payload }) => {
      state.current = payload.current;
    },
  },
});

// The reducer
export default menuSlice.reducer;

// Actions
export const {
  doApplyMenu, doApplyCurrentMenu,
} = menuSlice.actions;