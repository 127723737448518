import { LAYOUT, MENU_BEHAVIOUR, NAV_COLOR, MENU_PLACEMENT, RADIUS, THEME_COLOR, USER_ROLE } from 'constants.js';

export const BACKEND_URL = 'https://aatc.predictivehr.com/api/';
export const IS_DEMO = false;
export const IS_AUTH_GUARD_ACTIVE = true;
export const SERVICE_URL = '/app';
export const USE_MULTI_LANGUAGE = false;

// For detailed information: https://github.com/nfl/react-helmet#reference-guide
export const REACT_HELMET_PROPS = {
  defaultTitle: 'AATC - PredictiveHR',
  titleTemplate: '%s | Acquia - PredictiveHR',
};

export const DEFAULT_PATHS = {
  APP: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  USER_WELCOME: '/dashboards/default',
  NOTFOUND: '/page-not-found',
  UNAUTHORIZED: '/unauthorized',
  INVALID_ACCESS: '/invalid-access',
};

export const DEFAULT_SETTINGS = {
  MENU_PLACEMENT: MENU_PLACEMENT.Horizontal,
  MENU_BEHAVIOUR: MENU_BEHAVIOUR.Pinned,
  LAYOUT: LAYOUT.Fluid,
  RADIUS: RADIUS.Rounded,
  COLOR: THEME_COLOR.LightBlue,
  NAV_COLOR: NAV_COLOR.Default,
  USE_SIDEBAR: true,
};

export const DEFAULT_USER = {
  id: 1,
  name: 'Lisa Jackson',
  thumb: '/img/profile/profile-9.webp',
  role: USER_ROLE.Admin,
  email: 'lisajackson@gmail.com',
};

export const REDUX_PERSIST_KEY = 'classic-dashboard';



export const msalConfig = {
  auth: {
    clientId: "8abc5657-3089-402b-b61b-4282b724bcbc",
    authority: "https://login.microsoftonline.com/55594425-ad17-4811-bfce-f6f5fe6b5cdf", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: "https://aatc.predictivehr.com/login",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
/*
  auth2: {
   clientId: "5a99132f-ea65-491e-8a24-e5183d7b82c",
   authority:        "https://login.microsoftonline.com/4e05eaf6-195a-4b49-a01e-39736c5b69ca", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: "https://aatc.predictivehr.com/login",
 },
 cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }*/
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me/photo/$value"
};


export const googleConfig = {
  client_id: '114658461511-efbs1umkcqmpvf9ddkf6a17v1t0fpoi0.apps.googleusercontent.com',
  cookie_policy: 'single_host_origin'
};
